import React from 'react';
import ContactMenu from './ContactMenu';
import Container from './ContactMenu'
import "../styles/contact.css";


const Contact = () => {
  return (
    <>
    <Container/>
    </>
  )
};

export default Contact;
